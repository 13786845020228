import { Layout, Menu, Button, Input, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Cargo from "../../../img/cargo.jpg";
import {
  BarCodeType,
  CabinetNavigation,
} from "../../../configs/CabinetNavigation";
import CabinetDataTable, { IBarCodesList } from "./components/CabinetDataTable";
import { ICabinet } from "./ICabinet";
import {
  ArrowLeftOutlined,
  HomeOutlined,
  LeftOutlined,
  LogoutOutlined,
  RollbackOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  selectId,
  selectVerified,
  signOut,
} from "../../../redux/slices/customerSlice";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import AddNewBarCode from "./components/add-new-barcode";
import CabinetStatus from "./components/CabinetStatus";
import CabinetService from "../../../service/CabinetService";
import debounce from "lodash.debounce";
const { Header, Footer, Sider, Content } = Layout;

function Cabinet() {
  const customerId = useSelector(selectId);
  let history = useNavigate();
  let params = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!params["*"]) {
  //     history(`/cabinet/${BarCodeType.PACKAGE}`);
  //   }

  //   // history(`/cabinet`);
  //   //history(`/cabinet/${BarCodeType.PACKAGE}`);
  // }, [params["*"]]);

  const handleSignoutClick = () => {
    dispatch(signOut());
  };

  const [addModalOpen, setAddModalOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [barCodesList, setBarCodesList] = useState<IBarCodesList>({
    data: [],
    total: 0,
  });

  const sendQuery = (q: string) => {
    if (customerId === "") {
      return;
    }
    const type = params.barCodeType || "null";
    const status = params.status || "null";
    const paginateBarCodes = async (type: string, status: string) => {
      const result = await CabinetService.paginate([status], 1, 12, q);
      setBarCodesList({
        data: result.data.customerBarCodes,
        total: result.data.total,
      });
      // setLoading(false);
    };
    paginateBarCodes(type, status);
    // setCurrentPage(1);
  };

  const onChangeSearch = (e: any) => {
    setSearchQuery(e.target.value);
    delayedQuery(e.target.value);
  };

  const delayedQuery = useCallback(
    debounce((q) => sendQuery(q), 700),
    []
  );

  const openAddModalhandler = () => {
    setAddModalOpen(true);
  };

  return (
    <Layout hasSider style={{ minHeight: "100%" }}>
      {/* <Sider
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        // onBreakpoint={(broken) => {
        //   console.log(broken);
        // }}
        // onCollapse={(collapsed, type) => {
        //   console.log(collapsed, type);
        // }}
      >
        <div className="logo">
          <img src={Cargo} alt="" width={150} />
        </div>
      </Sider> */}
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            padding: 0,
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <LeftOutlined
              style={{ fontSize: 20, paddingLeft: 10, color: "#808080" }}
              onClick={() => history("/cabinet")}
            />
            {/* <Menu
              style={{ padding: 0, width: "min-conent" }}
              theme="light"
              mode="horizontal"
              //def={BarCodeType.PACKAGE}
              defaultSelectedKeys={[
                params["*"]?.split("/")[0] || BarCodeType.PACKAGE,
              ]}
              // activeKey={BarCodeType.PACKAGE}
              items={CabinetNavigation.map((nav, index) => ({
                key: nav.key,
                icon: React.createElement(nav.icon),
                label: nav.label,
              }))}
            /> */}

            <Button type="text" onClick={handleSignoutClick} danger>
              <LogoutOutlined /> <Space />
              Выйти
            </Button>
          </Row>
        </Header>
        <Content style={{ margin: "25px 16px 0" }}>
          <Row justify="space-between" style={{ marginBottom: "16px" }}>
            <Button type="primary" onClick={openAddModalhandler}>
              Добавить
            </Button>
            <Input
              style={{ width: 200 }}
              placeholder="Поиск"
              prefix={<SearchOutlined />}
              onChange={onChangeSearch}
            />
          </Row>
          <Routes>
            <Route path="/:status" element={<CabinetDataTable />} />
            <Route path="/" element={<CabinetStatus />} />
          </Routes>
        </Content>
        <Footer style={{ textAlign: "center", marginTop: 30 }}>
          Mereke cargo ©2024
        </Footer>

        <AddNewBarCode
          openData={addModalOpen}
          setOpenData={setAddModalOpen}
          setBarCodesList={setBarCodesList}
        />
      </Layout>
    </Layout>
  );
}

export default Cabinet;
