import React, { useEffect, useState } from "react";
import "./style.css";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { Clipboard } from "tabler-icons-react";
import { CopyFilled } from "@ant-design/icons";
import check_cargo from "../../../assets/check_cargo.svg";
import cargo_case from "../../../assets/cargo_case.svg";
import { MaskedInput } from "antd-mask-input";
import { current } from "@reduxjs/toolkit";
import CityService from "../../../service/CityService";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { openNotificationWithIcon } from "../../../utils/notificationUtil";

interface ICity {
  _id: string;
  titleRU: string;
  titleKZ: string;
  titleCN: string;
  shortTitle: string;
}

interface IAddressInfo {
  city: ICity | null;
  tel: string;
  name: string;
}

interface ICityList {
  cities: ICity[];
  currentPage: number;
  total: number;
}

const ChinaAddress = () => {
  const [addressInfo, setAddresInfo] = useState<IAddressInfo>({
    city: null,
    tel: "",
    name: "",
  });
  const [cities, setCitites] = useState<ICityList>({
    cities: [],
    currentPage: 1,
    total: 0,
  });
  useEffect(() => {
    async function fetchCitits() {
      const res = await CityService.fetchFilter(1, 100);

      setCitites(res.data);
    }
    fetchCitits();
  }, []);

  useEffect(() => {}, []);
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const copyFirst = () => {};

  const copySc = () => {};
  return (
    <div className="address_main">
      <Divider plain></Divider>
      <div className="address" id="address">
        {/* <hr className="address_hr" /> */}
        <div className="address_container">
          <div className="address_wrapper">
            <h2>Сіздің Қытай қоймасының мекенжайы</h2>

            <div className="address_form">
              <div className="address_form_item">
                <label htmlFor="">Регион</label>
                <Select
                  style={{ width: "100%" }}
                  // defaultValue="lucy"
                  // style={{ width: 120 }}
                  onChange={(value) => {
                    const changeCity = cities.cities.find(
                      (item) => item._id === value
                    );
                    if (!!changeCity) {
                      setAddresInfo((current) => ({
                        ...current,
                        city: changeCity,
                      }));
                    }
                  }}
                  options={cities.cities.map((item: ICity) => ({
                    label: item.titleKZ,
                    value: item._id,
                  }))}
                />
              </div>

              <div className="address_form_item">
                <label htmlFor="">Телефон</label>
                <MaskedInput
                  onChange={(e) =>
                    setAddresInfo((current) => ({
                      ...current,
                      tel: e.unmaskedValue,
                    }))
                  }
                  mask={
                    //  https://imask.js.org/guide.html#masked-pattern
                    "+7(000)000-00-00"
                  }
                />
              </div>

              <div className="address_form_item last">
                <label htmlFor="">Сіздің атыңыз ағылшын тілінде</label>
                <Input
                  onChange={(e) =>
                    setAddresInfo((current) => ({
                      ...current,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <Card className="address_card">
              <Row
                wrap={false}
                justify="space-between"
                style={{ marginTop: 32, padding: 8 }}
              >
                <p>
                  1.{" "}
                  <span>
                    {addressInfo.city?.shortTitle}-{addressInfo?.name}-
                    {addressInfo?.tel.slice(-4)}
                  </span>
                </p>

                <CopyToClipboard
                  text={`${addressInfo.city?.shortTitle}-${
                    addressInfo?.name
                  }-${addressInfo?.tel.slice(-4)}`}
                  onCopy={() => openNotificationWithIcon("success", "OK", "")}
                >
                  <Button
                    shape="circle"
                    icon={<Clipboard color="#3e85c7" />}
                    disabled={
                      !addressInfo.city?.shortTitle ||
                      addressInfo?.tel === "" ||
                      addressInfo.name === ""
                    }
                  />
                </CopyToClipboard>
              </Row>
              <Row
                wrap={false}
                justify="space-between"
                style={{ marginTop: 16, padding: 8 }}
              >
                <p>
                  2. <span>19120417178</span>
                </p>

                <CopyToClipboard
                  text={`19120417178`}
                  onCopy={() => openNotificationWithIcon("success", "OK", "")}
                >
                  <Button shape="circle" icon={<Clipboard color="#3e85c7" />} />
                </CopyToClipboard>
              </Row>
              <Row
                wrap={false}
                justify="space-between"
                style={{ marginTop: 16, padding: 8 }}
              >
                <p>3. 广东省 广州市 白云区</p>
                <CopyToClipboard
                  text={`广东省 广州市 白云区`}
                  onCopy={() => openNotificationWithIcon("success", "OK", "")}
                >
                  <Button shape="circle" icon={<Clipboard color="#3e85c7" />} />
                </CopyToClipboard>
              </Row>
              <Row
                wrap={false}
                justify="space-between"
                style={{ marginTop: 16, padding: 8 }}
              >
                <p>
                  4.{" "}
                  <span>石沙路289号正达产业园11号楼102仓优达国际178买库</span>
                  {addressInfo.city?.titleCN}
                  {addressInfo?.tel}
                  {addressInfo.name}
                </p>
                <CopyToClipboard
                  text={`石沙路289号正达产业园11号楼102仓优达国际178买库${addressInfo.city?.titleCN}${addressInfo?.tel}${addressInfo.name}`}
                  onCopy={() => openNotificationWithIcon("success", "OK", "")}
                >
                  <Button
                    shape="circle"
                    icon={<Clipboard color="#3e85c7" />}
                    disabled={
                      !addressInfo.city?.shortTitle ||
                      addressInfo?.tel === "" ||
                      addressInfo.name === ""
                    }
                  />
                </CopyToClipboard>
              </Row>
              <Row justify="center">
                {" "}
                <CopyToClipboard
                  text={`${addressInfo.city?.shortTitle}-${
                    addressInfo?.name
                  }-${addressInfo?.tel.slice(
                    -4
                  )} 19120417178 广东省 广州市 白云区 石沙路289号正达产业园11号楼102仓优达国际178买库${
                    addressInfo.city?.titleCN
                  }${addressInfo?.tel}${addressInfo.name}`}
                  onCopy={() => openNotificationWithIcon("success", "OK", "")}
                >
                  <Button
                    disabled={
                      !addressInfo.city?.titleCN ||
                      addressInfo?.tel === "" ||
                      addressInfo.name === ""
                    }
                    style={{
                      marginTop: 32,
                      marginBottom: 16,
                      borderRadius: 18,
                      backgroundColor: "#3e85c7",
                      opacity:
                        !addressInfo.city?.titleCN ||
                        !addressInfo?.tel ||
                        addressInfo.name === ""
                          ? 0.5
                          : 1,
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }}
                    icon={
                      <CopyFilled
                        style={{ fill: "#fff", color: "#fff" }}
                        color="#3e85c7"
                      />
                    }
                  >
                    <span
                      style={{
                        fill: "#fff",
                        color: "#fff",
                        marginLeft: 10,

                        // marginRight: "auto",
                      }}
                    >
                      Толық көшіру{" "}
                      {/* {addressInfo.tel.length === 0 ||
                    addressInfo.name.length === 0
                      ? "name" +
                        addressInfo.name.length +
                        " tel" +
                        addressInfo.tel.length
                      : addressInfo.name.length + " " + addressInfo.tel.length} */}
                    </span>{" "}
                  </Button>
                </CopyToClipboard>
              </Row>
            </Card>
          </div>
        </div>
        <img
          src={check_cargo}
          alt="check_cargo"
          className="check_cargo wow slideInRight"
        />
        <img
          src={cargo_case}
          alt="cargo_case"
          className="cargo_case wow slideInLeft"
        />
      </div>
    </div>
  );
};

export default ChinaAddress;
