import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import React from "react";
import {
  BrandTelegram,
  BrandWhatsapp,
  BrandInstagram,
  BrandTiktok,
  Headphones,
} from "tabler-icons-react";
import "./style.css";

const Footer = () => {
  return (
    <div className="footer">
      {/* logo */}
      <img src={"/cargo.png"} alt="Cargo" className="logo" />
      <div style={{ display: "flex", gap: 10 }} className="">
        <a href="https://t.me/MerekeCARGO" target={"_blank"}>
          <BrandTelegram size={20} strokeWidth={1} color={"#fff"} />
        </a>
        <a href="https://wa.me/+77776088844" target={"_blank"}>
          <BrandWhatsapp size={20} strokeWidth={1} color={"#fff"} />
        </a>
        <a
          href="https://instagram.com/Mereke_cargo?igshid=MTV5bXp4YXpsdDFndg%3D%3D&utm_source=qr"
          target={"_blank"}
        >
          <BrandInstagram size={20} strokeWidth={1} color={"#fff"} />
        </a>
        {/* <a href="" target={"_blank"}>
          <BrandTiktok size={20} strokeWidth={1} color={"#fff"} />
        </a> */}
      </div>

      <div className="contact">
        <div id="contacts" className="footer_top_right--contact">
          <h5>Контакты</h5>
          <a
            href="https://go.2gis.com/plv8e"
            className="footer_content"
            style={{
              display: "flex",

              alignItems: "center",
            }}
            target="_blank"
          >
            <EnvironmentOutlined style={{ color: "#fff" }} color="#fff" />
            <p>
              г. Алматы, Рынок Алмалы, торговый центр Шоссе Северное Кольцо,
              29/5, 2рят 343бутик
            </p>
          </a>
          <div className="footer_content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <a className="" href="tel:+77057620590">
                <PhoneOutlined /> +7 705 762 05 90
              </a>
              <a className="" href="tel:+77776088844">
                <PhoneOutlined /> +7 777 608 88 44
              </a>
            </div>
          </div>
          {/* <div className="footer_content">
            <MailOutlined />
            <p>1991logistics@gmail.com</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
