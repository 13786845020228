import { Button, Card, Image, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PhotoBannerService from "../../../../service/PhotoBannerService";
import AddPhotoBanner from "./AddPhotoBanner";
// import EditPhotoBanner from "./EditPhotoBanner";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_IMG_BANNER } from "../../../../configs/AppConfig";

const { confirm } = Modal;

const PhotoBanner = () => {
  const [openAdd, setOpenAdd] = useState({
    open: false,
    photoProductId: "",
  });

  const [loading, setLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState({
    open: false,
    photoProductId: "",
  });
  const [PhotoBanner, setPhotoBanner] = useState<any>([]);
  const [newPhotoBannerId, setNewPhotoBannerId] = useState("");

  const showDrawerAdd = async () => {
    setOpenAdd({
      open: true,
      photoProductId: "",
    });
  };

  const showDrawerEdit = (record: any) => {
    setOpenEdit({ open: true, photoProductId: record._id });
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await PhotoBannerService.fetchFilter(1);

      setPhotoBanner({
        data: result.data.bannerPhotos,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const columns = [
    // {
    //   title: "品名",
    //   dataIndex: "key",
    //   key: "key",
    // },
    {
      title: "图片",
      dataIndex: "img",
      key: "img",
      width: "70%",

      render: (text: string, record: any) => (
        <>
          <Image width={200} src={API_IMG_BANNER + text} />
        </>
      ),
    },

    {
      title: "更多",
      key: "action",

      render: (text: string, record: any) => (
        <>
          <Space size="middle">
            <Button
              danger
              type="primary"
              onClick={() => {
                deletePhotoBannerConfirm(record._id);
              }}
            >
              删除
            </Button>
          </Space>
        </>
      ),
    },
  ];

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setLoading(true);
    const res = await PhotoBannerService.fetchFilter(page);
    setPhotoBanner({
      data: res.data.bannerPhotos,
      total: res.data.total,
    });
    setLoading(false);
  };

  const deletePhotoBanner = async (id: string) => {
    await PhotoBannerService.delete(id);
    window.location.reload();
  };

  const deletePhotoBannerConfirm = (id: string) => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      title: "确认删除？",
      okText: "确认",
      cancelText: "取消",
      onOk() {
        deletePhotoBanner(id);
      },
    });
  };

  return (
    <Card>
      <Button
        onClick={showDrawerAdd}
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 20,
          backgroundColor: "green",
        }}
        type="primary"
      >
        添加
      </Button>
      <Table
        loading={loading}
        dataSource={PhotoBanner.data}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: 12,
          total: PhotoBanner.total,
          onChange: pageChangeHandle,
        }}
      />
      {openAdd && (
        <AddPhotoBanner openData={openAdd} setOpenData={setOpenAdd} />
      )}
      {/* {openEdit.open && (
        <EditPhotoBanner openData={openEdit} setOpenData={setOpenEdit} />
      )} */}
    </Card>
  );
};

export default PhotoBanner;
