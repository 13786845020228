import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  notification,
  Pagination,
  Select,
  Space,
  Spin,
} from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useRef, useState } from "react";
import BannerService from "../../../../service/BannerService";
import TextArea from "antd/lib/input/TextArea";
import PhotoService from "../../../../service/PhotoService";
import { API_IMG_BANNER } from "../../../../configs/AppConfig";
import PhotoBannerService from "../../../../service/PhotoBannerService";

function EditBanner({ openData, setOpenData }: any) {
  const formRef = useRef<any>();
  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<any>([]);
  const [selectLoading, setSelectLoading] = useState(false);
  useEffect(() => {
    const fetchBanner = async () => {
      const response = await BannerService.getOne(openData.bannerId);
      console.log("response", response);

      formRef.current.setFieldsValue({
        _id: response.data._id,
        img_kk: response.data.img_kk._id,
        img_ru: response.data.img_ru._id,
        img_zh: response.data.img_zh._id,
        link: response.data.link,
      });
      setLoading(false);
    };
    fetchBanner();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      const result = await PhotoBannerService.fetchFilter(1);

      setPhoto({
        data: result.data.bannerPhotos,
        total: result.data.total,
      });
      setLoading(false);
    };
    fetchOrders();
  }, []);

  const openNotification = (placement: NotificationPlacement) => {
    notification.success({
      message: `Success`,
      description: "更改成功",
      placement,
    });
  };
  const onClose = () => {
    setOpenData({ open: false, BannerId: "" });
  };
  const onFinish = async (values: any) => {
    setLoading(true);

    // _id: response.data._id,
    //     titleRU: response.data.titleRU,
    //     titleKZ: response.data.titleKZ,
    //     isMain: response.data.isMain,

    await BannerService.update(values, openData.bannerId);
    openNotification("topRight");
    setLoading(false);
  };

  const pageChangeHandle = async (page: number, pageSize: number) => {
    setSelectLoading(true);
    const res = await PhotoBannerService.fetchFilter(page);
    setPhoto({
      data: res.data.bannerPhotos,
      total: res.data.total,
    });
    setSelectLoading(false);
  };

  return (
    <Drawer
      title="修改FAQ"
      placement="right"
      onClose={onClose}
      open={openData.open}
      width={"50%"}
    >
      <Spin size="large" spinning={loading}>
        <Form
          ref={formRef}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item label="图片kz" name="img_kk" required>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选图片"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeHandle}
                    />
                  </Space>
                </>
              )}
            >
              {photo?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <Image width={55} src={API_IMG_BANNER + item.img} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="图片ru" name="img_ru" required>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选图片"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeHandle}
                    />
                  </Space>
                </>
              )}
            >
              {photo?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <Image width={55} src={API_IMG_BANNER + item.img} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="图片cn" name="img_zh" required>
            <Select
              style={{ width: "100%" }}
              showSearch
              placeholder="请选图片"
              optionFilterProp="children"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Pagination
                      defaultCurrent={1}
                      total={photo?.total}
                      pageSize={12}
                      onChange={pageChangeHandle}
                    />
                  </Space>
                </>
              )}
            >
              {photo?.data?.map((item: any) => (
                <Select.Option key={item._id} value={item._id}>
                  <Image width={55} src={API_IMG_BANNER + item.img} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="URL链接" name="link" required>
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 8, span: 16 }}

            // wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              添加
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditBanner;
