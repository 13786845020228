import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Col, Image, InputNumber, Radio, Row, Slider } from "antd";
import OrderService from "../../../service/OrderService";
import cargocase from "../../../assets/cargo_case.svg";
import priceHero from "../../../assets/price_hero.svg";
import plane from "../../../assets/plane.svg";
import motor from "../../../assets/motor.svg";
import coin from "../../../assets/coin.svg";
import CountUp from "react-countup";

const Price = () => {
  const [inputValue, setInputValue] = useState(1);
  const [tarif, setTarif] = useState(4);
  const prevInputValue = useRef(1);

  const onChange = (newValue: number) => {
    setInputValue(newValue);
  };

  useEffect(() => {
    prevInputValue.current = inputValue;
  }, [inputValue]);

  return (
    <div className="price" id="tarif">
      <div className="price_container">
        <div className="price_wrapper">
          <div className="price_wrapper_left">
            <h1>Ең тиімді бағалар</h1>
            <p>Тауарларыңызды Қазақстанға арзан бағамен жеткізіп аласыз.</p>
            <div className="price_slider">
              <h3>Тариф</h3>

              <Radio.Group
                onChange={(e) => {
                  setTarif(e.target.value);
                }}
                value={tarif}
              >
                <Radio value={4}>Алматы (4$)</Radio>
                <Radio value={4.5}>Регион (4.5$ - 4.7$)</Radio>
              </Radio.Group>

              <hr />

              <div className="price_slider_group">
                <h3>Салмағы</h3>
                <Row gutter={20}>
                  <Col span={20}>
                    <Slider
                      handleStyle={{
                        borderColor: "#3e85c7",
                      }}
                      trackStyle={{
                        backgroundColor: "#3e85c7",
                      }}
                      marks={{ 0: "0 кг", 50: "50 кг" }}
                      min={1}
                      max={50}
                      onChange={onChange}
                      value={typeof inputValue === "number" ? inputValue : 0}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber value={inputValue} onChange={onChange} />
                  </Col>
                </Row>
              </div>
              <div className="price_slider_group">
                <h3>Барлығы</h3>
                <h1>
                  <CountUp
                    duration={0.5}
                    separator=" "
                    decimals={2}
                    decimal=","
                    start={prevInputValue.current * tarif}
                    end={inputValue * tarif}
                  />
                  {tarif === 4.5 ? (
                    <>
                      <span>-</span>
                      <CountUp
                        duration={0.5}
                        separator=" "
                        decimals={2}
                        decimal=","
                        start={prevInputValue.current * 4.7}
                        end={inputValue * 4.7}
                      />
                    </>
                  ) : null}
                </h1>
              </div>
            </div>
          </div>
          <div className="checkCargo_logo">
            <div className="logos">
              <img
                src={priceHero}
                alt="priceHero"
                className="wow slideInRight"
              />

              {/* <img
                src={cargocase}
                alt="checkCargo"
                className="wow  slideInRight lightSpeedInRight"
              /> */}
            </div>
            <img src={plane} alt="plane" className="wow fadeIn plane" />
            <img
              src={motor}
              alt="priceHero"
              className="wow slideInRight motor"
            />
            <img src={coin} alt="coin" className="wow zoomIn coin" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
