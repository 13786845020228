import React from "react";
import "./style.css";
import hero from "../../../assets/hero.png";
import { PhoneOutlined } from "@ant-design/icons";
import { BrandWhatsapp } from "tabler-icons-react";
import { Button, Row } from "antd";

const Header = () => {
  return (
    <div className="header">
      {" "}
      <div className="header_container">
        <img src="/cargo.png" alt="cargo" />
        <ul className="header_nav">
          <li>
            <a href="#track"> Сәлемдемені қадағалау</a>
          </li>
          <li>
            <a href="#tarif"> Тариф</a>
          </li>
          <li>
            <a href="#tarif"> Қытай қоймасы</a>
          </li>
          <li>
            <a className="" href="tel:+77776088844">
              <PhoneOutlined /> +7 777 608 8844
            </a>
          </li>
          <li>
            <a href="https://wa.me/+7776088844" target={"_blank"}>
              <Button
                type="primary"
                style={{ background: "#3e85c7", border: "none" }}
              >
                <Row align="middle">
                  <BrandWhatsapp size={20} strokeWidth={1} color={"#fff"} />
                  <span>Whatsapp</span>
                </Row>
              </Button>
            </a>
          </li>
        </ul>
      </div>
      {/* <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul> */}
    </div>
  );
};

export default Header;
