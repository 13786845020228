import fetch from "../auth/FetchInterceptor";
const BannerService = {
  fetchFilter: function (page: number, limit = 12) {
    return fetch({
      url: `/banner/list?page=${page}&limit=${limit}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },
  update: function (data: any, id: string) {
    return fetch({
      url: `/banner/update/${id}`,
      method: "put",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },
  getOne: function (data: string) {
    return fetch({
      url: `/banner-getone/${data}`,
      method: "get",
      headers: {
        "public-request": "true",
      },
    });
  },

  addThumbnail: function (data: any) {
    return fetch({
      url: `/banner-thumbnail/add`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
        "content-type": "multipart/form-data",
      },
    });
  },
  create: function (data?: any) {
    return fetch({
      url: `/banner/create`,
      method: "post",
      data: data,
      headers: {
        "public-request": "true",
      },
    });
  },

  delete: function (data: string) {
    return fetch({
      url: `/banner/delete/${data}`,
      method: "delete",
      headers: {
        "public-request": "true",
      },
    });
  },
};

export default BannerService;
